/**
 * This file is used to define the constants for split testing and all things advertisements.
 *
 * It'll have the names of events used to track the split testing, engagement, and advertisement events.
 *
 **/

export const USER_CLICKS_TRY_DEMO_NOW = "user_clicks_try_demo_now";
export const USER_UPLOADS_RESUME_TO_DEMO = "try_demo_uploaded_resume";
// export const USER_WATCHES_VIDEO_HOMEPAGE = "user_watches_video_homepage";

export const USER_BOOK_DEMO_FORM_SUBMISSION = "user_book_demo_form_submission";
