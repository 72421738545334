"use client";
import React from "react";
const ContactUs = ({ component: CustomComponent = null }) => {
  const handleContactClick = () => {
    window.open(
      "mailto:joseph@swelens.com?body=Let%20us%20know%20how%20we%20can%20help",
      "_blank"
    );
  };

  if (CustomComponent) {
    // If a custom component is provided, clone it and add the onClick handler
    return React.cloneElement(CustomComponent, {
      onClick: handleContactClick,
    });
  }

  // Default button if no custom component is provided
  return (
    <button
      onClick={handleContactClick}
      className="bg-purple-600 text-white px-4 py-2 rounded-md shadow hover:bg-purple-700 transition normal-case"
    >
      Contact Us
    </button>
  );
};

export default ContactUs;