export const CONTENT_TYPE_PROJECT = "project";
export const CONTENT_TYPE_CANDIDATE = "candidate";

export const CONTENT_TYPE_JOB = "job";

export const DEFAULT_CANDIDATE_NAME = "Jane Doe";
export const DEFAULT_JOB_NAME = "Full Stack Developer";
export const FORM_RESET_VALUE = "reset";

export const ROUTE_LOGIN_PAGE = "/auth/login";
export const ROUTE_SIGNUP_PAGE = "/auth/signup";
export const ROUTE_HOME_PAGE = "/home";

export const ROUTE_GET_JOBS = "/api/jobs";
export const ROUTE_GET_REPO_ANALYSIS = "/api/repo-analysis";
export const ROUTE_GET_SUBSCRIPTION_INFO = "/api/user-subscription";
export const ENDPOINT_CANCEL_SUBSCRIPTION = "/api/cancel-stripe-subscription";
export const ENDPOINT_GET_DEMO = "/api/demo";
export const ENDPOINT_GET_JOB = "/api/job";
export const ENDPOINT_GET_CANDIDATE_RESUME =
  "/api/candidate-resume/{candidate_id}";
export const ENDPOINT_GET_CANDIDATE = "/api/candidate/{candidate_id}";

export const TOAST_TYPE_SUCCESS = "success";
export const TOAST_TYPE_PARTIAL_ERROR = "partial";
export const TOAST_TYPE_ERROR = "error";
export const TOAST_TYPE_MORE_INFO = "more-info";

// TO DO: remove this
// export const DEMO_PDF_URL =
//   "https://jofuelqfdpqthjasbhlf.supabase.co/storage/v1/object/public/demo_resumes/ResumeDemoSweLens.pdf?t=2024-12-03T02%3A13%3A05.740Z";
export const DEMO_PDF_URL = `${process.env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/object/public/demo_resumes/ResumeDemoSweLens.pdf?t=2024-12-03T02%3A13%3A05.740Z`;

// Server constants
// export const ENDPOINT_TASK_STATUS = `${process.env.BACKEND_ANALYSIS_BASE_URL}/tasks/{task_id}/status`;
export const ENDPOINT_TASK_STATUS = `${process.env.BACKEND_ANALYSIS_BASE_URL}/task/{task_id}/status`;

// Local storage keys
export const LOCAL_STORAGE_DEMO_KEY = "SWELensDemoId";

// Advertisement Landing Page Constants
export const AD_LANDING_PAGE_QUERY_PARAM = "campaign";
export const AD_LANDING_PAGE_QUERY_PARAM_BOOK_DEMO = "bookDemo";
export const AD_LANDING_PAGE_QUERY_PARAM_TRY_IT_NOW = "tryItNow";
export const AD_LANDING_PAGE_QUERY_PARAM_GET_STARTED = "getStarted";

export const BOOKING_LINK = "https://calendar.app.google/XxwUQ5SnF3P147xy9";
